'use client'

import React, { ReactElement, ReactNode } from 'react'
import classNames from 'classnames'
import { AgencyData } from '@/types/Misc'

export interface HeaderWrapperProps {
  searchPageOrBookmarks: boolean
  gaMeasurementId: string | undefined
  agencyData: AgencyData
  menuItemsDesktop: ReactNode
  mobileNavigation: ReactNode
  logo: ReactNode
  secondaryNavigation: ReactNode
}

export function HeaderWrapper(props: HeaderWrapperProps): ReactElement {
  return (
    <div
      className={classNames(
        'fix-mobile-header grid min-h-[48px] w-full max-w-screen-xl grid-cols-[1fr_,_auto_,_1fr] items-center justify-between px-3 lg:mx-0 lg:mb-2.5 lg:mt-10 lg:grid-cols-[auto_,_1fr] lg:px-0',
        {
          'lg:x-6 w-full': props.searchPageOrBookmarks,
        },
      )}
      data-ga-measurement-id={props.gaMeasurementId}
    >
      {props.mobileNavigation}
      {props.logo}
      <div
        className={classNames(
          'fix-secondary-wrapper relative col-start-3 flex min-w-0 flex-col lg:col-start-2',
        )}
      >
        {props.secondaryNavigation}
        {props.menuItemsDesktop}
      </div>
    </div>
  )
}
