'use client'

import React, { ReactElement, useContext } from 'react'
import { HeaderContext } from '@/components/common/header/HeaderContext'
import { withIcon } from '@/components/common/header/util'

export function MobileNavToggleButton(): ReactElement {
  const { toggleNavbar } = useContext(HeaderContext)

  return (
    <button
      aria-label="Mobile Menu"
      className={withIcon(
        'before:icon-header-menu',
        'no-tap-highlight relative flex flex h-[44px] w-[44px] flex-row items-center items-center justify-center rounded-[100%] text-xl font-regular text-black-84 before:inline-flex before:h-[44px] before:items-center before:justify-center active:bg-[#E3E3E3] lg:hidden lg:before:w-[60%]',
      )}
      onClick={toggleNavbar}
      id="open-navbar"
    />
  )
}
