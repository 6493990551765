'use client'

import React, { ChangeEvent, ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import { ConfigContext } from '@/components/common/ConfigContext'
import { CURRENCIES, Currency, CURRENCY_COOKIE } from '@/types/Page'
import Cookies from 'js-cookie'
import { COOKIE_LIFETIME_IN_DAYS, CURRENCY_SEARCH_PARAMETER } from '@/lib/sharedConstants'
import classNames from 'classnames'

export function CurrencySelect(props: {
  breadcrump: ReactNode
}): ReactElement {
  const defaultCurrency = useContext(ConfigContext)!.currency
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency)

  useEffect(
    () => setSelectedCurrency((Cookies.get(CURRENCY_COOKIE) as Currency) || defaultCurrency),
    [setSelectedCurrency, defaultCurrency],
  )

  function urlWithoutCurrency(): string {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(window.location.search)
    params.delete(CURRENCY_SEARCH_PARAMETER)
    url.search = params.toString()

    return url.toString()
  }

  function switchCurrency(e: ChangeEvent<HTMLSelectElement>): void {
    Cookies.set(CURRENCY_COOKIE, e.target.value, { expires: COOKIE_LIFETIME_IN_DAYS })

    window.location.replace(urlWithoutCurrency())
  }

  return <div
    className={classNames('mx-auto flex items-center justify-center py-4 ', {
      'sm:justify-between': props.breadcrump,
      'sm:justify-end': !props.breadcrump,
    })}
  >
    {props.breadcrump}

    <select
      className='rounded-md border-stone-200'
      onChange={switchCurrency}
      value={selectedCurrency}
      id='currency-select'
    >
      {CURRENCIES.map(c => <option key={c} value={c}>
        {c}
      </option>)}
    </select>
  </div>
}