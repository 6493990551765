'use client'

import { createContext, ReactElement, ReactNode, useState } from 'react'

interface HeaderContextProps {
  addNavBarToDom: boolean
  setAddNavBarToDom: (b: boolean) => void
  navbarOpen: boolean
  setNavbarOpen: (b: boolean) => void
  toggleNavbar: () => void
}

export const HeaderContext = createContext<HeaderContextProps>({} as HeaderContextProps)

export default function HeaderContextProvider({
  children,
  single,
}: {
  children: ReactNode
  single: boolean
}): ReactElement {
  const [addNavBarToDom, setAddNavBarToDom] = useState(single)
  const [navbarOpen, setNavbarOpen] = useState(false)

  function toggleNavbar(): void {
    if (addNavBarToDom) {
      setNavbarOpen((prev) => !prev)
    } else {
      setAddNavBarToDom(true)
      setTimeout(() => setNavbarOpen((prev) => !prev), 50)
    }
  }

  return (
    <HeaderContext.Provider
      value={{
        addNavBarToDom,
        setAddNavBarToDom,
        navbarOpen,
        setNavbarOpen,
        toggleNavbar,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}
