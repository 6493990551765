'use client'

import React, { ReactElement, useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { getTimeSearchQueryGeneral, loadLikedHotels } from '@/lib/utils/utils'
import { loadSavedSearch } from '@/components/ui/wizard/wizardUtils'
import { ConfigContext } from '@/components/common/ConfigContext'

export function LikedHotelsIcon(props: {singleHeader: boolean}): ReactElement {
  const config = useContext(ConfigContext)!
  const [numberLikedHotels, setNumberLikedHotels] = useState<number | undefined>(props.singleHeader ? 0 : undefined)

  useEffect(() => {
    setNumberLikedHotels(loadLikedHotels().length)
    window.addEventListener('storage', () => {
      setNumberLikedHotels(loadLikedHotels().length)
    })
  }, [])

  function clicked(): void {
    const savedSearch = loadSavedSearch()
    const params = new URLSearchParams(
      savedSearch
        ? (getTimeSearchQueryGeneral(
          savedSearch.selectedOptionDuration,
          savedSearch.flexibleTravelPeriodSelected,
          savedSearch.selectedOptionCalendarFlexibility,
          savedSearch.selectedOptionPeriods,
          savedSearch.startDate,
          savedSearch.endDate,
        ) as Record<string, string>)
        : {},
    )
    window.open(`${config.header.bookmarks.url}?${params.toString()}`, '_blank')
  }

  return (
    <li
      className='no-tap-highlight mx-0 mb-0 flex translate-y-[-1px] cursor-pointer items-center lg:translate-y-[3px]'
      id='like'
      onClick={clicked}
    >
      <div className='relative' id='like-click-container'>
        <div className='h-[30px] w-[30px] bg-cover icon-header-heart lg:h-[22px] lg:w-[22px] lg:translate-y-[-6px]' />
        {' '}

        {numberLikedHotels !== undefined && <div
          className={classNames(
            'absolute bottom-0 right-[0px] z-40 flex h-[14px] w-auto items-center rounded-3xl border-[1px] border-white px-[0px] text-xxs text-white before:border-white md:px-[0px] lg:bottom-[5px] lg:right-[-3px]',
            {
              'bg-primary': numberLikedHotels > 0,
              'bg-[#C0C0C0]': numberLikedHotels == 0,
              'hidden': props.singleHeader
            },
          )}
          id='liked-hotels-container'
        >
          <span
            id='liked-hotel-count'
            className='z-40 flex translate-y-[0px] items-center justify-center px-[3px] text-center text-[10px] font-regular leading-[0px] lg:translate-x-[-0px]'
          >
            {numberLikedHotels}
          </span>
        </div>}
      </div>
    </li>
  )
}

