'use client'

import { AgencyData } from '@/types/Misc'
import classNames from 'classnames'
import PhoneIcon from '@/icons/header-phone.svg'
import { ContactLinkLabelHiddenMobile } from '@/components/common/header/ContactLink'
import React, { useContext, useEffect } from 'react'
import { loadSavedSearch } from '@/components/ui/wizard/wizardUtils'
import { ConfigContext } from '@/components/common/ConfigContext'

const SHOW_PHONE_TRESHOLD = 7
const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000

export function PhoneNumber(props: { agencyData: AgencyData }) {
  const config = useContext(ConfigContext)!

  const [showPhone, setShowPhone] = React.useState(
    config.header.contactPhone.showByDefault || config.header.contactPhone.showAlways,
  )

  useEffect(() => {
    if (config.header.contactPhone.showAlways) {
      return
    }

    function showHidePhone() {
      const savedSearch = loadSavedSearch()
      const startDate = savedSearch?.startDate ? new Date(savedSearch?.startDate) : undefined
      const endDate = savedSearch?.endDate ? new Date(savedSearch?.endDate) : undefined
      const duration = savedSearch?.selectedOptionDuration?.min

      function showHideBasedOnDuration(duration: number) {
        if (duration > SHOW_PHONE_TRESHOLD) {
          setShowPhone(true)
        } else {
          setShowPhone(false)
        }
      }

      if (startDate && endDate) {
        const differenceInDays = Math.floor(
          (endDate?.getTime() - startDate?.getTime()) / ONE_DAY_IN_MILLISECONDS,
        )
        showHideBasedOnDuration(differenceInDays)

        return
      }

      if (duration) {
        showHideBasedOnDuration(duration)

        return
      }

      setShowPhone(config.header.contactPhone.showByDefault)
    }

    showHidePhone()

    //add event listener to update showPhone state, should be triggered when window.localStorage is updated
    window.addEventListener('storage', showHidePhone)

    return () => window.removeEventListener('storage', showHidePhone)
  }, [])

  return (
    <a
      href={`tel:${props.agencyData.PhoneE164}`}
      aria-label={props.agencyData.PhoneDisplay}
      className={classNames('flex flex-row items-center space-x-1', { hidden: !showPhone })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PhoneIcon className="h-[30px] lg:h-[20px]" />
      <ContactLinkLabelHiddenMobile label={props.agencyData.PhoneDisplay} />
    </a>
  )
}
