'use client'

import React, { ReactElement, ReactNode, useContext } from 'react'
import classNames from 'classnames'
import { HeaderContext } from '@/components/common/header/HeaderContext'
import { MobileNavigationHeader } from '@/components/common/header/MobileNavigationHeader'
import { AgencyData } from '@/types/Misc'

export function MobileNavigation(props: {
  menuLabel: ReactNode
  phoneIcon: ReactNode
  mailIcon: ReactNode
  serviceIcon: ReactNode
  agencyData: AgencyData
  single: boolean
  menuItems: ReactNode
}): ReactElement {
  const { addNavBarToDom, navbarOpen, toggleNavbar } = useContext(HeaderContext)

  return (
    <>
      {addNavBarToDom && (
        <div
          className={classNames('fixed top-0 z-[200] h-[100vh] w-full bg-white duration-300', {
            'left-[0%]': navbarOpen,
            'left-[-100%]': !navbarOpen,
          })}
          id="mobile-navigation"
        >
          <div className="container relative mx-auto h-full max-w-screen-xl p-5 py-1 duration-0">
            <MobileNavigationHeader onClick={toggleNavbar} menuLabel={props.menuLabel} />
            {props.menuItems}

            <ul className="b-0 absolute border-t-[1px] pb-8 pl-4 pt-4 md:ml-[38%] md:pl-0">
              <li>{props.serviceIcon}</li>
              <li>{props.mailIcon}</li>
              {props.agencyData.PhoneDisplay && <li id="phoneMobile">{props.phoneIcon}</li>}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}
