import React, { ReactElement } from 'react'
import { withIcon } from '@/components/common/header/util'

export function MobileNavigationHeader(props: {
  onClick: () => void
  menuLabel: React.ReactNode
}): ReactElement {
  return (
    <div className="flex border-b-[1px] pb-0.5">
      <button
        className={withIcon(
          'before:icon-header-back-mobile',
          'no-tap-highlight flex flex h-[44px] w-[44px] translate-x-[-3px] translate-y-[0px] flex-row items-center justify-center rounded-[100%] before:inline-flex before:h-[44px] before:w-[28px] before:w-[30px] before:items-center before:justify-center active:bg-[#E3E3E3]',
        )}
        onClick={props.onClick}
        aria-label="Close Navbar"
        id="close-navbar"
      ></button>
      <div className="mx-auto my-auto translate-x-[-20px] text-center text-xl font-regular text-stone-600  md:ml-[calc(38%_-_9px)] md:text-left">
        {props.menuLabel}
      </div>
    </div>
  )
}
