'use client'

import React, { ReactElement, useContext, useMemo } from 'react'
import classNames from 'classnames'
import { Wizard } from '@/components/ui/wizard/Wizard'
import { ConfigContext } from '@/components/common/ConfigContext'
import { wizardLabels } from '@/lib/sharedConstants'
import dynamic from 'next/dynamic'
import { ReactComponentLike } from 'prop-types'

export function HeaderWizardWrapper(props: {
  bookmarksPage: boolean
  skipHeaderButKeepSearch: boolean | undefined
  searchPageOrBookmarks: boolean
  searchPage: boolean
}): ReactElement {
  const config = useContext(ConfigContext)!

  return (
    <div
      className={classNames(
        'sticky top-[-1px] z-30 flex w-full justify-center bg-white shadow-bottom-md lg:fixed',
        {
          'lg:top-[92px] lg:mt-2': !props.skipHeaderButKeepSearch,
        },
      )}
    >
      <div
        className={classNames('grid gap-x-[10px] gap-y-[9px]', {
          'w-full max-w-screen-xl px-2 lg:grid-cols-[auto_1fr] lg:px-5 ': true,
          'grid-rows-[54px_48px] pt-3 lg:grid-rows-[44px] lg:pb-4': !props.bookmarksPage,
          'pb-4 lg:mb-2 lg:grid-rows-[48px]': props.bookmarksPage,

          'w-screen lg:pl-4': props.searchPageOrBookmarks,
        })}
      >
        {props.searchPage && !props.bookmarksPage && (
          <Wizard
            {...wizardLabels}
            searchWizard={true}
            currency={config.currency}
            bookmarksPage={props.bookmarksPage}
            searchPage={props.searchPage}
          />
        )}
        {props.bookmarksPage && <HeaderTravelPeriodSelect />}
      </div>
    </div>
  )
}

function HeaderTravelPeriodSelect(): ReactElement {
  const RealHeaderTravelPeriodSelect = useMemo(
    () =>
      dynamic(() => import('@/components/common/header/HeaderTravelPeriodSelect'), {
        ssr: false,
      }),
    [],
  ) as ReactComponentLike

  return <RealHeaderTravelPeriodSelect />
}
